const cfg = require(`../configs/${process.env.NODE_ENV}.json`)

export default function (ctx) {
  // { $axios, redirect }

  ctx.$axios.setBaseURL(cfg.axios.apiBaseURL)
  ctx.$axios.onRequest((config) => {
    // console.log('axiosWrap ==> 1', ctx.store.state.token)

    const store = ctx.store
    if (store.state.token !== undefined && store.state.token !== null) {
      config.headers.common.Accept = 'application/json'
      // console.log('___ttttokennnn___', store.state.token)
      config.headers.common.Authorization = 'Bearer ' + store.state.token
      config.withCredentials = false
    }
  })

  ctx.$axios.onResponse((response) => {
    // console.log('aziosWrapperrrr onResponse')
    // console.log(response.status, response.data.data)

    // const store = ctx.store
    // if (response.status === 401) {
    //   store.dispatch('logout')
    // }

    // if (token !== undefined) {
    //   const store = ctx.store
    //   store.commit('setState', { token: `${token}` })
    // }
    return response
  })

  ctx.$axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      // ctx.redirect('/')
    }
  })
}
