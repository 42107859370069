// import { getAuth } from 'firebase/auth'

const state = () => ({
  authUser: null
})

const getters = {
  getAuthUser (state) {
    return state.authUser
  }
}

const actions = {
  setAuthUser: ({ commit, state }, payload) => {
    commit('SET_AUTH_USER', payload)
  },
  signout: ({ commit, state }, payload) => {
    commit('SET_AUTH_USER', null)

    // const backUrl = localStorage.getItem('back_url')
    // console.log('DEBUGGGG', context.state.authUser, context)
    // if (context.state.authUser) {
    //   if (backUrl) {
    //     localStorage.removeItem('back_url')
    //     // this.$router.push(backUrl)
    //     context.redirect(backUrl)
    //   } else {
    //     // this.$router.push('/')
    //     context.redirect('/')
    //   }
    // } else {
    //   context.redirect('/')
    // }
  }
}

const mutations = {
  SET_AUTH_USER (state, payload) {
    state.authUser = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
