// // ~/plugins/firebase.js

// // import firebase from 'firebase'

// import { getApp, initializeApp } from 'firebase/app'

// // const app = null
// let firebaseConfig = {}

// if (process.env.NODE_ENV === 'production') {
//   firebaseConfig = {
//     apiKey: 'AIzaSyCv4Fp-AN5nbawSM7RxB_sTNOLJKmklm50',
//     authDomain: 'locopack-web.firebaseapp.com',
//     databaseURL: 'https://locopack-web.firebaseio.com',
//     projectId: 'locopack-web',
//     storageBucket: 'locopack-web.appspot.com',
//     messagingSenderId: '154139258032',
//     appId: '1:154139258032:web:3fce2e526e9fb8eb039f75'
//   }
// } else {
//   firebaseConfig = {
//     apiKey: 'AIzaSyCsYd8zaqOqeVW_zl7LlYb17EryznEzW8M',
//     authDomain: 'locopack-web-dev.firebaseapp.com',
//     projectId: 'locopack-web-dev',
//     storageBucket: 'locopack-web-dev.appspot.com',
//     messagingSenderId: '173549698863',
//     appId: '1:173549698863:web:5a48e8226e07247eafabda'
//   }
// }
// let firebaseApp
// try {
//   firebaseApp = getApp()
// } catch (e) {
//   firebaseApp = initializeApp(firebaseConfig)
// }

// export const auth = firebaseApp
// export default (context) => {
//   const { store } = context

//   return new Promise((resolve, reject) => {
//     store.dispatch('callbackAuthen')
//     return resolve()
//   })
// }

// ~/plugins/firebase.js

import { getApp, initializeApp } from 'firebase/app'
import { getFirestore, updateDoc, getDocs, query, where, collection, setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { formatDateToYMD } from './helpers'
let firebaseConfig = {}

if (process.env.NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyCv4Fp-AN5nbawSM7RxB_sTNOLJKmklm50',
    authDomain: 'locopack-web.firebaseapp.com',
    databaseURL: 'https://locopack-web.firebaseio.com',
    projectId: 'locopack-web',
    storageBucket: 'locopack-web.appspot.com',
    messagingSenderId: '154139258032',
    appId: '1:154139258032:web:3fce2e526e9fb8eb039f75'
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyCsYd8zaqOqeVW_zl7LlYb17EryznEzW8M',
    authDomain: 'locopack-web-dev.firebaseapp.com',
    projectId: 'locopack-web-dev',
    storageBucket: 'locopack-web-dev.appspot.com',
    messagingSenderId: '173549698863',
    appId: '1:173549698863:web:5a48e8226e07247eafabda'
  }
}
let firebaseApp
try {
  firebaseApp = getApp()
} catch (e) {
  firebaseApp = initializeApp(firebaseConfig)
}

export const auth = firebaseApp
// export default (context) => {
export default ({ store }, inject) => {
  const firebasePlugin = async () => {
    try {
      // Example: Increment the count_click column for a specific template
      const templateName = 'template_click'
      const db = getFirestore(firebaseApp)

      // Define the document reference (replace 'your_document_id' with the actual ID)
      // const docRef = doc(db, templateName, '2AZR7zYDDOG2nPmcDjXH')

      const collectionRef = collection(db, templateName) // Replace with actual collection reference

      // Get the documents where the date field matches a specific date
      const q = query(collectionRef, where('date_filter', '==', formatDateToYMD(new Date())))
      // Fetch the document
      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        const docSnapshot = await querySnapshot.docs[0]
        if (docSnapshot.exists()) {
          const docRef = doc(collectionRef, docSnapshot.id)
          const docData = docSnapshot.data()
          // Get the value from the document data
          const value = docData.count_click

          // Update the value as needed
          const updatedValue = value + 1

          // Update the document with the new value
          await updateDoc(docRef, { count_click: updatedValue }, { merge: true })

          // Commit the updated value to the store
          // store.commit('template/setCount', updatedValue) // Replace with your store and mutation
        } else {
          const newDocData = {
            count_click: 1, // Set initial count value
            date_filter: formatDateToYMD(new Date())
          }
          await setDoc(doc(collectionRef), newDocData)
        }
      } else {
        const newDocData = {
          count_click: 1, // Set initial count value
          date_filter: formatDateToYMD(new Date())
        }
        await setDoc(doc(collectionRef), newDocData)
      }

      const collectionAllRef = collection(db, 'template_click_all') // Replace with actual collection reference
      const qAll = query(collectionAllRef, where('total_click', '>=', 0))
      const querySnapshotAll = await getDocs(qAll)
      if (!querySnapshotAll.empty) {
        const docSnapshotAll = await querySnapshotAll.docs[0]
        if (docSnapshotAll.exists()) {
          const docRefAll = doc(collectionAllRef, docSnapshotAll.id)
          const docDataAll = docSnapshotAll.data()
          const valueAll = docDataAll.total_click

          // Update the value as needed
          const updatedValueAll = valueAll + 1
          await updateDoc(docRefAll, { total_click: updatedValueAll, updated_at: serverTimestamp() }, { merge: true })
        }
      }
      // querySnapshotAll.forEach((docSnapshot) => {
      //   if (docSnapshot.exists()) {
      //     const data = docSnapshot.data()
      //     console.log('Document data:', data)
      //   } else {
      //     console.log('Document does not exist.')
      //   }
      // })
      // Dispatch the 'callbackAuthen' action
      store.dispatch('callbackAuthen')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in $firebasePlugin:', error)
    }
  }

  const firebaseBannerPlugin = async () => {
    try {
      // Example: Increment the count_click column for a specific template
      const templateName = 'template_banner_click'
      const db = getFirestore(firebaseApp)

      // Define the document reference (replace 'your_document_id' with the actual ID)
      // const docRef = doc(db, templateName, '2AZR7zYDDOG2nPmcDjXH')

      const collectionRef = collection(db, templateName) // Replace with actual collection reference

      // Get the documents where the date field matches a specific date
      const q = query(collectionRef, where('date_filter', '==', formatDateToYMD(new Date())))
      // Fetch the document
      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        const docSnapshot = await querySnapshot.docs[0]
        if (docSnapshot.exists()) {
          const docRef = doc(collectionRef, docSnapshot.id)
          const docData = docSnapshot.data()
          // Get the value from the document data
          const value = docData.count_click

          // Update the value as needed
          const updatedValue = value + 1

          // Update the document with the new value
          await updateDoc(docRef, { count_click: updatedValue }, { merge: true })

          // Commit the updated value to the store
          // store.commit('template/setCount', updatedValue) // Replace with your store and mutation
        } else {
          const newDocData = {
            count_click: 1, // Set initial count value
            date_filter: formatDateToYMD(new Date())
          }
          await setDoc(doc(collectionRef), newDocData)
        }
      } else {
        const newDocData = {
          count_click: 1, // Set initial count value
          date_filter: formatDateToYMD(new Date())
        }
        await setDoc(doc(collectionRef), newDocData)
      }

      const collectionAllRef = collection(db, 'template_banner_click_all') // Replace with actual collection reference
      const qAll = query(collectionAllRef, where('total_click', '>=', 0))
      const querySnapshotAll = await getDocs(qAll)
      if (!querySnapshotAll.empty) {
        const docSnapshotAll = await querySnapshotAll.docs[0]
        if (docSnapshotAll.exists()) {
          const docRefAll = doc(collectionAllRef, docSnapshotAll.id)
          const docDataAll = docSnapshotAll.data()
          const valueAll = docDataAll.total_click

          // Update the value as needed
          const updatedValueAll = valueAll + 1
          await updateDoc(docRefAll, { total_click: updatedValueAll, updated_at: serverTimestamp() }, { merge: true })
        }
      }
      // querySnapshotAll.forEach((docSnapshot) => {
      //   if (docSnapshot.exists()) {
      //     const data = docSnapshot.data()
      //     console.log('Document data:', data)
      //   } else {
      //     console.log('Document does not exist.')
      //   }
      // })
      // Dispatch the 'callbackAuthen' action
      store.dispatch('callbackAuthen')
    } catch (error) {
      console.error('Error in $firebaseBannerPlugin:', error)
    }
  }
  inject('firebaseBannerPlugin', firebaseBannerPlugin)

  inject('firebasePlugin', firebasePlugin)
}
