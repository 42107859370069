import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faGooglePlusSquare } from '@fortawesome/free-brands-svg-icons/faGooglePlusSquare'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faStop } from '@fortawesome/free-solid-svg-icons/faStop'

library.add(faFacebookSquare, faGooglePlusSquare, faTimes, faKey,
  faBars, faChevronRight, faChevronLeft, faPencilAlt, faTrashAlt,
  faQuestionCircle, faShare, faSearch, faSearchPlus, faChevronDown, faChevronUp,
  faCheckCircle, faCheck, faStop)

Vue.component('font-awesome-icon', FontAwesomeIcon)
