import { extend } from 'vee-validate'
import { required, min, email, numeric, digits } from 'vee-validate/dist/rules'
extend('required', {
  ...required,
  message: 'กรุณากรอกข้อมูล'
})
extend('numeric', {
  ...numeric,
  message: 'กรุณากรอกตัวเลข'
})
extend('digits', {
  ...digits,
  message: 'กรุณาระบุตัวเลข {length} หลัก'
})
extend('min', {
  ...min,
  message: 'จำนวนอักขระไม่ถึงขั้นเริ่ม'
})
extend('email', {
  ...email,
  message: 'รูปแบบ email ไม่ถูกต้อง'
})
extend('verify_password', {
  message: 'รหัสผ่านต้องมีความยาว 8 - 15 ตัวอักษร และประกอบด้วย ตัวเลข ตัวอักษรภาษาอังกฤษ หรือ อักขระพิเศษ ! @ # $ % ^ & * _ เท่านั้น',
  validate: (value) => {
    const strongRegex = new RegExp(/^[a-zA-Z0-9!@#$%^&*_]{8,15}$/)
    return strongRegex.test(value)
  }
})
extend('accept', {
  message: 'กรุณายอมรับเงื่อนไขการใช้บริการ',
  validate: (value) => {
    return value === true
  }
})
