export default (context, inject) => {
  const replaceSEO = (seo, title, id, subId, category, subCategory, description, keyword) => {
    const data = seo
    let x
    for (x in data) {
      if (data[x] && title) {
        data[x] = data[x].toString().replace('{title}', title)
      }
      if (data[x] && id) {
        data[x] = data[x].toString().replace('{id}', id)
      }
      if (data[x] && subId) {
        data[x] = data[x].toString().replace('{subid}', subId)
      }
      if (data[x] && category) {
        data[x] = data[x].toString().replace('{category}', category)
      }
      if (data[x] && subCategory) {
        data[x] = data[x].toString().replace('{sub_category}', subCategory)
      }
      if (data[x] && description) {
        data[x] = data[x].toString().replace('{description}', description)
      }
      if (data[x] && keyword) {
        data[x] = data[x].toString().replace('{keyword}', keyword)
      }
    }
    return seo
  }
  inject('replaceSEO', replaceSEO)
  context.$replaceSEO = replaceSEO
}
